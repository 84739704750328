import {
  createApi,
  fetchBaseQuery,
} from '@reduxjs/toolkit/query/react';
import Swal from 'sweetalert2';
import { refreshToken } from '../../utils/axiosApi';

const baseQuery = fetchBaseQuery({
  baseUrl: `${process.env.REACT_APP_BACKEND_URL}etiq`,
  prepareHeaders: (headers, _) => {
    const token = localStorage.getItem('access_token');
    if (token) {
      headers.set('Authorization', `JWT ${token}`);
    }
    return headers;
  },
});

const baseQueryWithReauth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);

  if (result.error && result.error.status === 401) {
    // Token might be expired, try to refresh it
    const refreshTokenValue = localStorage.getItem('refresh_token');
    if (refreshTokenValue) {
      try {
        const refreshResult = await refreshToken();

        if (refreshResult.status === 200) {
          // Retry the original request with the new token
          result = await baseQuery(args, api, extraOptions);
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Session Expired',
            text: 'Your session has expired. Please log in again.',
          });
          window.location.href = '/login';
        }
      } catch (refreshError) {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'An error occurred while refreshing the token.',
        });
        window.location.href = '/login';
      }
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Unauthorized',
        text: 'Please log in again.',
      });
      window.location.href = '/login';
    }
  } else if (result.error) {
    console.log(result.error);
    Swal.fire({
      icon: 'error',
      title: 'Zut alors...',
    });
  }

  return result;
};

export const apiSlice = createApi({
  reducerPath: 'apiEtiq',
  baseQuery: baseQueryWithReauth,
  tagTypes: [
    'Contracts',
    'Contract',
    'UserEtiqByContracts',
    'UserEtiqByContract',
  ],
  endpoints: () => ({}), // Define empty endpoints initially
});
