import { configureStore } from '@reduxjs/toolkit';
import { apiSlice as apiSliceDrooky } from './drooky/apiSlice';
import { apiSlice as apiSliceEtiq } from './etiq/apiSlice';

export const store = configureStore({
  reducer: {
    [apiSliceDrooky.reducerPath]: apiSliceDrooky.reducer,
    [apiSliceEtiq.reducerPath]: apiSliceEtiq.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      apiSliceDrooky.middleware,
      apiSliceEtiq.middleware,
    ),
});
